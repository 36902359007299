var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "AdvancedSetup_wrapper" },
    [
      _c(
        "el-tabs",
        { attrs: { type: "card" } },
        [
          _c(
            "el-tab-pane",
            { attrs: { label: "导航设置" } },
            [
              _c(
                "el-table",
                {
                  staticStyle: { width: "800px" },
                  attrs: {
                    data: _vm.iconTableData,
                    border: "",
                    stripe: "",
                    size: "mini"
                  }
                },
                [
                  _c("el-table-column", {
                    attrs: { prop: "date", label: "导航名称" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c("el-input", {
                              attrs: {
                                placeholder: "输入导航名称",
                                maxlength: "5",
                                "show-word-limit": ""
                              },
                              model: {
                                value: scope.row.name,
                                callback: function($$v) {
                                  _vm.$set(scope.row, "name", $$v)
                                },
                                expression: "scope.row.name"
                              }
                            })
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "url", label: "跳转链接" }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "defaultLogo",
                      label: "默认图标",
                      width: "100px"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c("div", { staticClass: "icon_wrap" }, [
                              _c("img", {
                                attrs: { src: scope.row.defaultLogo, alt: "" }
                              })
                            ])
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "logo", label: "选中图标", width: "100px" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c("div", { staticClass: "icon_wrap" }, [
                              _c("img", {
                                attrs: { src: scope.row.logo, alt: "" }
                              })
                            ])
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "logo", label: "操作", width: "100px" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c(
                              "el-tag",
                              {
                                on: {
                                  click: function($event) {
                                    return _vm.ModifyIcon(scope.$index)
                                  }
                                }
                              },
                              [_vm._v("修改图标")]
                            )
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              ),
              _c(
                "el-button",
                {
                  staticClass: "mt20",
                  attrs: { type: "primary" },
                  on: { click: _vm.confirmNav }
                },
                [_vm._v("保 存")]
              )
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "分享设置" } },
            [
              _c(
                "el-form",
                {
                  ref: "shareForm",
                  staticClass: "share-ruleForm",
                  attrs: {
                    model: _vm.shareForm,
                    rules: _vm.shareRules,
                    "label-width": "120px"
                  }
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "分享封面", prop: "linkPoster" } },
                    [
                      _c("div", { staticClass: "left_advertising" }, [
                        _c("div", [
                          _c(
                            "label",
                            {
                              staticClass: "el-upload el-upload--picture-card",
                              attrs: { for: "uploadSharePoster" }
                            },
                            [
                              _c("input", {
                                attrs: {
                                  type: "file",
                                  hidden: "",
                                  id: "uploadSharePoster",
                                  name: "upload",
                                  accept: "image/*"
                                },
                                on: { change: _vm.uploadSharePoster }
                              }),
                              _vm.shareForm.linkPoster
                                ? _c("img", {
                                    staticStyle: {
                                      width: "142px",
                                      height: "142px"
                                    },
                                    attrs: { src: _vm.shareForm.linkPoster }
                                  })
                                : _c("i", {
                                    staticClass:
                                      "el-icon-plus avatar-uploader-icon"
                                  })
                            ]
                          ),
                          _c("span", { staticClass: "ml10 form_tips" }, [
                            _vm._v(
                              "建议尺寸120*120 px，支持JPG，PNG，JPEG格式，小于4M"
                            )
                          ])
                        ])
                      ])
                    ]
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "分享标题", prop: "linkTitle" } },
                    [
                      _c(
                        "div",
                        { staticClass: "left_advertising" },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "请填写标题，最多输入30个字",
                              maxlength: "30",
                              "show-word-limit": ""
                            },
                            model: {
                              value: _vm.shareForm.linkTitle,
                              callback: function($$v) {
                                _vm.$set(_vm.shareForm, "linkTitle", $$v)
                              },
                              expression: "shareForm.linkTitle"
                            }
                          })
                        ],
                        1
                      )
                    ]
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "分享描述", prop: "linkDesc" } },
                    [
                      _c(
                        "div",
                        { staticClass: "left_advertising" },
                        [
                          _c("el-input", {
                            staticStyle: { width: "400px" },
                            attrs: {
                              type: "textarea",
                              placeholder: "请填写分享描述，最多输入38个字",
                              maxlength: "38",
                              "show-word-limit": ""
                            },
                            model: {
                              value: _vm.shareForm.linkDesc,
                              callback: function($$v) {
                                _vm.$set(_vm.shareForm, "linkDesc", $$v)
                              },
                              expression: "shareForm.linkDesc"
                            }
                          })
                        ],
                        1
                      )
                    ]
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: {
                            click: function($event) {
                              return _vm.confirmShare("shareForm")
                            }
                          }
                        },
                        [_vm._v("保 存")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "数据优化" } },
            [
              _c("div", { staticClass: "data_optimization" }, [
                _c("h2", [_vm._v("数据定义：")]),
                _c("ul", [
                  _c("li", [_vm._v("• 已报名人数 - 成功提交报名信息的人数")]),
                  _c("li", [
                    _vm._v(
                      "• 已分享人次：获知成功分享直播链接的人数，即判断分享链接是否有其他用户进入"
                    )
                  ]),
                  _c("li", [
                    _vm._v(
                      "• 已浏览人次：即页面浏览量，同一用户重复进入，数据累计"
                    )
                  ])
                ])
              ]),
              _c(
                "div",
                { staticClass: "mt20 mb20" },
                [
                  _vm._v(" 数据展示： "),
                  _c("el-switch", {
                    model: {
                      value: _vm.IsOpenOptimize,
                      callback: function($$v) {
                        _vm.IsOpenOptimize = $$v
                      },
                      expression: "IsOpenOptimize"
                    }
                  }),
                  _c("span", { staticClass: "ml10" }, [
                    _vm._v("关闭后，数据不展示在微信端页面")
                  ])
                ],
                1
              ),
              _c(
                "el-table",
                {
                  staticStyle: { width: "100%" },
                  attrs: {
                    data: _vm.optimizationTable,
                    border: "",
                    stripe: "",
                    size: "mini"
                  }
                },
                [
                  _c("el-table-column", {
                    attrs: { prop: "title", label: "", width: "180" }
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "realData", label: "真实数据", width: "180" }
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "fakeData", label: "虚拟数据" }
                  }),
                  _c("el-table-column", {
                    attrs: { label: "操作" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function($event) {
                                    return _vm.settingData(
                                      scope.$index,
                                      scope.row.key
                                    )
                                  }
                                }
                              },
                              [_vm._v("数据设置")]
                            )
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              ),
              _c(
                "el-button",
                {
                  staticClass: "mt10",
                  attrs: { type: "primary" },
                  on: { click: _vm.saveActivityOptimizeData }
                },
                [_vm._v("保 存")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-drawer",
        {
          attrs: {
            title: "修改图标",
            visible: _vm.iconDrawer,
            "custom-class": "icon_drawer"
          },
          on: {
            "update:visible": function($event) {
              _vm.iconDrawer = $event
            }
          }
        },
        [
          _c("div", { staticClass: "bule_tit" }, [
            _vm._v("默认图标（上传后需点击选择上传图片再保存）")
          ]),
          _c("div", { staticClass: "disflex flexdr justifysa alignic mb20" }, [
            _c(
              "div",
              {
                staticClass: "icon_drawer_item",
                class: { icon_drawer_item_chosen: _vm.iconDrawerObj.Default },
                on: {
                  click: function($event) {
                    _vm.iconDrawerObj.Default = true
                  }
                }
              },
              [
                _c("el-image", {
                  attrs: { src: _vm.iconDrawerObj.presetDefaultLogo }
                }),
                _c("p", [_vm._v("预设图标库")])
              ],
              1
            ),
            _vm.iconDrawerObj.uploadDefaultLogo
              ? _c(
                  "div",
                  {
                    staticClass: "icon_drawer_item",
                    class: {
                      icon_drawer_item_chosen: !_vm.iconDrawerObj.Default
                    },
                    on: {
                      click: function($event) {
                        _vm.iconDrawerObj.Default = false
                      }
                    }
                  },
                  [
                    _c("el-image", {
                      attrs: { src: _vm.iconDrawerObj.uploadDefaultLogo }
                    }),
                    _c("p", [_vm._v("从本地上传图标")])
                  ],
                  1
                )
              : _vm._e(),
            _c("div", { staticClass: "disflex justifycc" }, [
              _c(
                "label",
                { attrs: { for: "uploadDefaultIcon" } },
                [
                  _c("input", {
                    attrs: {
                      type: "file",
                      id: "uploadDefaultIcon",
                      hidden: "",
                      name: "upload",
                      accept: "image/*"
                    },
                    on: { change: _vm.uploadDefaultIcon }
                  }),
                  _c("el-tag", { staticClass: "cp" }, [_vm._v("上传图片")])
                ],
                1
              )
            ])
          ]),
          _c("div", { staticClass: "bule_tit" }, [
            _vm._v("选中图标（上传后需点击选择上传图片再保存）")
          ]),
          _c("div", { staticClass: "disflex flexdr justifysa alignic mb20" }, [
            _c(
              "div",
              {
                staticClass: "icon_drawer_item",
                class: { icon_drawer_item_chosen: _vm.iconDrawerObj.Checked },
                on: {
                  click: function($event) {
                    _vm.iconDrawerObj.Checked = true
                  }
                }
              },
              [
                _c("el-image", {
                  attrs: { src: _vm.iconDrawerObj.presetLogo }
                }),
                _c("p", [_vm._v("预设图标库")])
              ],
              1
            ),
            _vm.iconDrawerObj.uploadLogo
              ? _c(
                  "div",
                  {
                    staticClass: "icon_drawer_item",
                    class: {
                      icon_drawer_item_chosen: !_vm.iconDrawerObj.Checked
                    },
                    on: {
                      click: function($event) {
                        _vm.iconDrawerObj.Checked = false
                      }
                    }
                  },
                  [
                    _c("el-image", {
                      attrs: { src: _vm.iconDrawerObj.uploadLogo }
                    }),
                    _c("p", [_vm._v("从本地上传图标")])
                  ],
                  1
                )
              : _vm._e(),
            _c("div", { staticClass: "disflex justifycc mb20" }, [
              _c(
                "label",
                { attrs: { for: "uploadCheckedIcon" } },
                [
                  _c("input", {
                    attrs: {
                      type: "file",
                      id: "uploadCheckedIcon",
                      hidden: "",
                      name: "upload",
                      accept: "image/*"
                    },
                    on: { change: _vm.uploadCheckedIcon }
                  }),
                  _c("el-tag", { staticClass: "cp" }, [_vm._v("上传图片")])
                ],
                1
              )
            ])
          ]),
          _c(
            "div",
            { staticClass: "disflex justifycc" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.confirmIconDrawer }
                },
                [_vm._v("保 存")]
              )
            ],
            1
          )
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            width: "30%",
            title:
              "已" +
              (_vm.dataKey == "share"
                ? "分享"
                : _vm.dataKey == "viewer"
                ? "浏览"
                : "报名") +
              "人次",
            visible: _vm.showBaseData
          },
          on: {
            "update:visible": function($event) {
              _vm.showBaseData = $event
            }
          }
        },
        [
          _c(
            "el-form",
            { attrs: { "label-width": "120px", model: _vm.baseData } },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label:
                      "真实" +
                      (_vm.dataKey == "share"
                        ? "分享"
                        : _vm.dataKey == "viewer"
                        ? "浏览"
                        : "报名") +
                      "人次"
                  }
                },
                [
                  _c("el-input", {
                    attrs: {
                      readonly: "readonly",
                      value: _vm.baseData.realData
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "是否开启" } },
                [
                  _c("el-switch", {
                    model: {
                      value: _vm.baseData.open,
                      callback: function($$v) {
                        _vm.$set(_vm.baseData, "open", $$v)
                      },
                      expression: "baseData.open"
                    }
                  }),
                  _c("span", { staticClass: "ml10" }, [
                    _vm._v(
                      "关闭后，显示真实的" +
                        _vm._s(
                          _vm.dataKey == "share"
                            ? "分享"
                            : _vm.dataKey == "viewer"
                            ? "浏览"
                            : "报名"
                        ) +
                        "人次"
                    )
                  ])
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label:
                      "虚拟" +
                      (_vm.dataKey == "share"
                        ? "分享"
                        : _vm.dataKey == "viewer"
                        ? "浏览"
                        : "报名") +
                      "人次"
                  }
                },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.baseData.fakeData,
                      callback: function($$v) {
                        _vm.$set(_vm.baseData, "fakeData", _vm._n($$v))
                      },
                      expression: "baseData.fakeData"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function($event) {
                          _vm.showBaseData = false
                        }
                      }
                    },
                    [_vm._v("取消")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.saveChange }
                    },
                    [_vm._v("保存更改")]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "fz14 cae", staticStyle: { "line-height": "24px" } },
            [
              _c("p", [_vm._v("注：")]),
              _vm.dataKey == "share"
                ? _c("ul", [
                    _c("li", [
                      _vm._v(
                        "• 已分享人次=虚拟分享人次(固定)+真实分享人次(动态)"
                      )
                    ]),
                    _c("li", [
                      _vm._v(
                        "• 如，当真实分享人次为1000时，虚拟分享人次为2000，总分享人次(3000)=真实分享人次"
                      )
                    ]),
                    _c("li", [
                      _vm._v(
                        "• (1000)+虚拟分享人次(2000)，同时随着实际分享人次增加总分享人次不断增加"
                      )
                    ])
                  ])
                : _vm.dataKey == "sign"
                ? _c("ul", [
                    _c("li", [
                      _vm._v(
                        "• 已报名人数=虚拟报名人数(固定)+真实报名人数(动态)"
                      )
                    ]),
                    _c("li", [
                      _vm._v(
                        "• 如，当真实报名人数为1000时，虚拟报名人数为2000，总报名人数(3000)=真实报名人数"
                      )
                    ]),
                    _c("li", [
                      _vm._v(
                        "• (1000)+虚拟报名人数(2000)，同时随着实际报名人数增加总报名人数不断增加"
                      )
                    ])
                  ])
                : _vm.dataKey == "viewer"
                ? _c("ul", [
                    _c("li", [
                      _vm._v(
                        "• 已浏览人次=虚拟浏览人次(固定)+真实浏览人次(动态)"
                      )
                    ]),
                    _c("li", [
                      _vm._v(
                        "• 如，当真实浏览人次为1000时，虚拟浏览人次为2000，总浏览人次(3000)=真实浏览人次"
                      )
                    ]),
                    _c("li", [
                      _vm._v(
                        "• (1000)+虚拟浏览人次(2000)，同时随着实际浏览人次增加总浏览人次不断增加"
                      )
                    ])
                  ])
                : _vm._e()
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div class="AdvancedSetup_wrapper">
    <el-tabs type="card">
      <el-tab-pane label="导航设置">
        <el-table :data="iconTableData" border stripe size="mini" style="width: 800px">
          <el-table-column prop="date" label="导航名称">
            <template slot-scope="scope">
              <el-input v-model="scope.row.name" placeholder="输入导航名称" maxlength="5" show-word-limit></el-input>
            </template>
          </el-table-column>
          <el-table-column prop="url" label="跳转链接"></el-table-column>
          <el-table-column prop="defaultLogo" label="默认图标" width="100px">
            <template slot-scope="scope">
              <div class="icon_wrap">
                <img :src="scope.row.defaultLogo" alt />
                <!-- <p>修改</p> -->
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="logo" label="选中图标" width="100px">
            <template slot-scope="scope">
              <div class="icon_wrap">
                <img :src="scope.row.logo" alt />
                <!-- <p>修改</p> -->
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="logo" label="操作" width="100px">
            <template slot-scope="scope">
              <el-tag @click="ModifyIcon(scope.$index)">修改图标</el-tag>
            </template>
          </el-table-column>
        </el-table>
        <el-button type="primary" class="mt20" @click="confirmNav">保 存</el-button>
      </el-tab-pane>
      <el-tab-pane label="分享设置">
        <el-form
          ref="shareForm"
          :model="shareForm"
          :rules="shareRules"
          label-width="120px"
          class="share-ruleForm"
        >
          <el-form-item label="分享封面" prop="linkPoster">
            <div class="left_advertising">
              <div>
                <label for="uploadSharePoster" class="el-upload el-upload--picture-card">
                  <input
                    type="file"
                    hidden
                    id="uploadSharePoster"
                    name="upload"
                    accept="image/*"
                    @change="uploadSharePoster"
                  />
                  <img
                    v-if="shareForm.linkPoster"
                    :src="shareForm.linkPoster"
                    style="width:142px;height:142px;"
                  />
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </label>
                <span class="ml10 form_tips">建议尺寸120*120 px，支持JPG，PNG，JPEG格式，小于4M</span>
              </div>
            </div>
          </el-form-item>
          <el-form-item label="分享标题" prop="linkTitle">
            <div class="left_advertising">
              <el-input
                v-model="shareForm.linkTitle"
                placeholder="请填写标题，最多输入30个字"
                maxlength="30"
                show-word-limit
              ></el-input>
            </div>
          </el-form-item>
          <el-form-item label="分享描述" prop="linkDesc">
            <div class="left_advertising">
              <el-input
                type="textarea"
                v-model="shareForm.linkDesc"
                placeholder="请填写分享描述，最多输入38个字"
                maxlength="38"
                show-word-limit
                style="width:400px"
              ></el-input>
            </div>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="confirmShare('shareForm')">保 存</el-button>
          </el-form-item>
        </el-form>
      </el-tab-pane>
      <el-tab-pane label="数据优化">
        <div class="data_optimization">
          <h2>数据定义：</h2>
          <ul>
            <li>• 已报名人数 - 成功提交报名信息的人数</li>
            <li>• 已分享人次：获知成功分享直播链接的人数，即判断分享链接是否有其他用户进入</li>
            <li>• 已浏览人次：即页面浏览量，同一用户重复进入，数据累计</li>
          </ul>
        </div>
        <div class="mt20 mb20">
          数据展示：
          <el-switch v-model="IsOpenOptimize"></el-switch>
          <span class="ml10">关闭后，数据不展示在微信端页面</span>
        </div>
        <el-table :data="optimizationTable" border stripe size="mini" style="width: 100%">
          <el-table-column prop="title" label width="180"></el-table-column>
          <el-table-column prop="realData" label="真实数据" width="180"></el-table-column>
          <el-table-column prop="fakeData" label="虚拟数据"></el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <!-- @click="settingData(-1, scope.row.key)" -->
              <el-button
                type="text"
                size="small"
                @click="settingData(scope.$index, scope.row.key)"
              >数据设置</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-button type="primary" class="mt10" @click="saveActivityOptimizeData">保 存</el-button>
      </el-tab-pane>
    </el-tabs>

    <!-- 修改图标 -->
    <el-drawer title="修改图标" :visible.sync="iconDrawer" custom-class="icon_drawer">
      <div class="bule_tit">默认图标（上传后需点击选择上传图片再保存）</div>
      <div class="disflex flexdr justifysa alignic mb20">
        <div
          class="icon_drawer_item"
          :class="{ 'icon_drawer_item_chosen': iconDrawerObj.Default }"
          @click="iconDrawerObj.Default=true"
        >
          <el-image :src="iconDrawerObj.presetDefaultLogo"></el-image>
          <p>预设图标库</p>
        </div>
        <div
          v-if="iconDrawerObj.uploadDefaultLogo"
          class="icon_drawer_item"
          :class="{ 'icon_drawer_item_chosen': !iconDrawerObj.Default }"
          @click="iconDrawerObj.Default=false"
        >
          <el-image :src="iconDrawerObj.uploadDefaultLogo"></el-image>
          <p>从本地上传图标</p>
        </div>
        <div class="disflex justifycc">
          <label for="uploadDefaultIcon">
            <input
              type="file"
              id="uploadDefaultIcon"
              hidden
              name="upload"
              accept="image/*"
              @change="uploadDefaultIcon"
            />
            <el-tag class="cp">上传图片</el-tag>
          </label>
        </div>
      </div>

      <div class="bule_tit">选中图标（上传后需点击选择上传图片再保存）</div>
      <div class="disflex flexdr justifysa alignic mb20">
        <div
          class="icon_drawer_item"
          :class="{ 'icon_drawer_item_chosen': iconDrawerObj.Checked }"
          @click="iconDrawerObj.Checked=true"
        >
          <el-image :src="iconDrawerObj.presetLogo"></el-image>
          <p>预设图标库</p>
        </div>
        <div
          v-if="iconDrawerObj.uploadLogo"
          class="icon_drawer_item"
          :class="{ 'icon_drawer_item_chosen': !iconDrawerObj.Checked }"
          @click="iconDrawerObj.Checked=false"
        >
          <el-image :src="iconDrawerObj.uploadLogo"></el-image>
          <p>从本地上传图标</p>
        </div>
        <div class="disflex justifycc mb20">
          <label for="uploadCheckedIcon">
            <input
              type="file"
              id="uploadCheckedIcon"
              hidden
              name="upload"
              accept="image/*"
              @change="uploadCheckedIcon"
            />

            <el-tag class="cp">上传图片</el-tag>
          </label>
        </div>
      </div>
      <div class="disflex justifycc">
        <el-button type="primary" @click="confirmIconDrawer">保 存</el-button>
      </div>
    </el-drawer>
    <el-dialog
      width="30%"
      :title="'已'+(dataKey == 'share' ? '分享': dataKey == 'viewer' ? '浏览' : '报名')+'人次'"
      :visible.sync="showBaseData"
    >
      <el-form label-width="120px" :model="baseData">
        <el-form-item
          :label="'真实'+(dataKey == 'share' ? '分享': dataKey == 'viewer' ? '浏览' : '报名')+'人次'"
        >
          <el-input readonly="readonly" :value="baseData.realData"></el-input>
        </el-form-item>
        <el-form-item label="是否开启">
          <el-switch v-model="baseData.open"></el-switch>
          <span
            class="ml10"
          >关闭后，显示真实的{{dataKey == 'share' ? '分享': dataKey == 'viewer' ? '浏览' : '报名'}}人次</span>
        </el-form-item>
        <el-form-item
          :label="'虚拟'+(dataKey == 'share' ? '分享': dataKey == 'viewer' ? '浏览' : '报名')+'人次'"
        >
          <el-input v-model.number="baseData.fakeData"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button @click="showBaseData = false">取消</el-button>
          <el-button type="primary" @click="saveChange">保存更改</el-button>
        </el-form-item>
      </el-form>

      <div class="fz14 cae" style="line-height: 24px;">
        <p>注：</p>
        <ul v-if="dataKey == 'share'">
          <li>• 已分享人次=虚拟分享人次(固定)+真实分享人次(动态)</li>
          <li>• 如，当真实分享人次为1000时，虚拟分享人次为2000，总分享人次(3000)=真实分享人次</li>
          <li>• (1000)+虚拟分享人次(2000)，同时随着实际分享人次增加总分享人次不断增加</li>
        </ul>
        <ul v-else-if="dataKey == 'sign'">
          <li>• 已报名人数=虚拟报名人数(固定)+真实报名人数(动态)</li>
          <li>• 如，当真实报名人数为1000时，虚拟报名人数为2000，总报名人数(3000)=真实报名人数</li>
          <li>• (1000)+虚拟报名人数(2000)，同时随着实际报名人数增加总报名人数不断增加</li>
        </ul>
        <ul v-else-if="dataKey == 'viewer'">
          <li>• 已浏览人次=虚拟浏览人次(固定)+真实浏览人次(动态)</li>
          <li>• 如，当真实浏览人次为1000时，虚拟浏览人次为2000，总浏览人次(3000)=真实浏览人次</li>
          <li>• (1000)+虚拟浏览人次(2000)，同时随着实际浏览人次增加总浏览人次不断增加</li>
        </ul>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import ActivityAjax from "@/utils/https/modules/Activity.request.js";
import VzanUpload from "@/utils/https/VzanUpload.js";

export default {
  name: "AdvancedSetup", // 高级设置
  components: {},
  props: {
    activityID: String // 活动ID
  },
  data() {
    return {
      upload: null, // 上传对象
      baseInfo: {
        poster: ""
      },
      input_test: "",
      iconTableData: [],
      IsOpenOptimize: false,
      optimizationTable: [
        {
          name: "已报名人数",
          key: "sign",
          truthful: 5,
          virtual: 5
        },
        {
          name: "已报名人数",
          key: "share",
          truthful: 5,
          virtual: 5
        },
        {
          name: "已报名人数",
          key: "viewer",
          truthful: 5,
          virtual: 5
        }
      ],
      showBaseData: false,
      dataKey: "",
      baseDataIndex: 0,
      baseData: {
        realData: 0,
        open: false,
        fakeData: 0
      },
      iconDrawer: false, // 修改图标
      iconDrawerObj: {}, // 修改图标临时变量存储
      iconIndex: 0,
      shareForm: {
        linkPoster: "", // 分享封面
        linkDesc: "", // 分享描述
        linkTitle: "" // 分享标题
      },
      shareRules: {
        linkPoster: [
          { required: true, message: "请上传分享封面", trigger: "blur" }
        ],
        linkDesc: [
          {
            required: true,
            message: "请输入分享描述",
            trigger: "blur"
          }
        ],
        linkTitle: [
          {
            required: true,
            message: "请输入分享标题",
            trigger: "blur"
          }
        ]
      },
      // 数据设置model
      baseDataModel: {},
      OptimizeId: 0
    };
  },
  computed: {},
  watch: {},
  methods: {
    // 查询导航设置信息
    async getActivityNavList() {
      try {
        const { data } = await ActivityAjax.getActivityNavList(this.activityID);
        this.iconTableData = data;
      } catch (error) {
        this.$message.error(error);
      }
    },
    // 高级设置-分享设置-获取
    async getActivityShareInfo() {
      try {
        const { data } = await ActivityAjax.getActivityShareInfo(
          this.activityID
        );
        this.shareForm = Object.assign({}, data);
      } catch (error) {
        this.$message.error(error);
      }
    },
    // 获取数据优化列表
    async getOptimizeData() {
      try {
        const { data } = await ActivityAjax.getActivityOptimizeData(
          this.activityID
        );
        console.log(data);

        var pagelist = [
          {
            title: "已报名人数",
            key: "sign",
            realData: data.realEnrollmentNum,
            open: data.enrollmentStatus=='active'?true:false,
            fakeData: data.enrollmentNum
          },
          {
            title: "已分享人次",
            key: "share",
            realData: data.realShareNum,
            open: data.shareStatus=='active'?true:false,
            fakeData: data.shareNum
          },
          {
            title: "已浏览人次",
            key: "viewer",
            realData: data.realViewNum,
            open: data.viewStatus=='active'?true:false,
            fakeData: data.viewNum
          }
        ];
        this.IsOpenOptimize = data.status == "forbidden" ? false : true;
        this.OptimizeId = data.id;
        this.optimizationTable = pagelist;
      } catch (error) {
        this.$message.error(error);
      }
    },
    // 进入数据详情
    settingData(index, key) {
      this.dataKey = key;
      this.showBaseData = true;
      this.baseDataIndex = index;
      console.log(index,key);
      this.baseData = JSON.parse(JSON.stringify(this.optimizationTable[index]));
    },
    // 数据优化列表- 保存
    async saveActivityOptimizeData() {
      try {
        this.baseDataModel.status = this.IsOpenOptimize
          ? "active"
          : "forbidden";
        this.baseDataModel.id = this.OptimizeId;
        await ActivityAjax.saveActivityOptimizeData(this.baseDataModel);
        this.baseDataModel = {};
        this.$message.success("保存成功");
        await this.getOptimizeData();
      } catch (error) {
        this.$message.error(error);
      }
    },
    // 单独保存一种数据
    async saveChange() {
      try {
        const dataNum = Number(this.baseData.fakeData);
        const num = Number.isNaN(dataNum);
        if (num) {
          this.$message.error("请输入正确的虚拟报名人次");
          return;
        }
        // 最大值限制
        if (dataNum > 99999999) {
          this.$message.error("虚拟报名人次不得超过 99999999");
          return;
        }
        if (dataNum < 0) {
          this.$message.error("虚拟报名人次不得小于 0");
          return;
        }
        if (this.dataKey == "share") {
          this.baseDataModel.shareStatus = this.baseData.open
            ? "active"
            : "forbidden";
          this.baseDataModel.shareNum = this.baseData.fakeData;
        } else if (this.dataKey == "sign") {
          this.baseDataModel.enrollmentStatus = this.baseData.open
            ? "active"
            : "forbidden";
          this.baseDataModel.enrollmentNum = this.baseData.fakeData;
        } else if (this.dataKey == "viewer") {
          this.baseDataModel.viewStatus = this.baseData.open
            ? "active"
            : "forbidden";
          this.baseDataModel.viewNum = this.baseData.fakeData;
        }
        this.baseDataModel.id = this.OptimizeId;
        await ActivityAjax.saveActivityOptimizeData(this.baseDataModel);
        this.baseDataModel = {};
        this.showBaseData = false;
        this.$message.success("保存成功");
        await this.getOptimizeData();
      } catch (error) {
        this.$message.error(error);
      }
    },

    // 上传
    async uploadSharePoster(event) {
      try {
        const { src } = await this.upload.uploadImage(event, {
          maxSize: 4
        });
        this.shareForm.linkPoster = src;
      } catch (error) {
        error && this.$message.warning(error.message);
      }
    },
    // 默认图标 上传
    async uploadDefaultIcon(event) {
      try {
        const { src } = await this.upload.uploadImage(event, {
          maxSize: 4
        });
        this.iconDrawerObj.uploadDefaultLogo = src;
        this.iconDrawerObj.Default = false;
      } catch (error) {
        error && this.$message.warning(error.message);
      }
    },
    // 选中图标 上传
    async uploadCheckedIcon(event) {
      try {
        const { src } = await this.upload.uploadImage(event, {
          maxSize: 4
        });
        this.iconDrawerObj.uploadLogo = src;
        this.iconDrawerObj.Checked = false;
      } catch (error) {
        error && this.$message.warning(error.message);
      }
    },
    // 修改图标 - 确认
    confirmIconDrawer() {
      const index = this.iconIndex;
      if (!this.iconDrawerObj.Default) {
        this.iconTableData[
          index
        ].defaultLogo = this.iconDrawerObj.uploadDefaultLogo;
      } else {
        this.iconTableData[
          index
        ].defaultLogo = this.iconDrawerObj.presetDefaultLogo;
      }
      if (!this.iconDrawerObj.Checked) {
        this.iconTableData[index].logo = this.iconDrawerObj.uploadLogo;
      } else {
        this.iconTableData[index].logo = this.iconDrawerObj.presetLogo;
      }
      this.iconDrawerObj = {};
      this.iconDrawer = false;
    },
    // 修改图标
    ModifyIcon(index) {
      this.iconDrawer = true;
      this.iconIndex = index;
      let modifyObj = Object.assign({}, this.iconTableData[index]);
      if (modifyObj.defaultLogo === modifyObj.presetDefaultLogo) {
        modifyObj.Default = true;
        modifyObj.uploadDefaultLogo = "";
      } else {
        modifyObj.Default = false;
        modifyObj.uploadDefaultLogo = modifyObj.defaultLogo;
      }

      if (modifyObj.logo === modifyObj.presetLogo) {
        modifyObj.Checked = true;
        modifyObj.uploadLogo = "";
      } else {
        modifyObj.Checked = false;
        modifyObj.uploadLogo = modifyObj.logo;
      }

      this.iconDrawerObj = modifyObj;
    },
    // 导航设置- 保存
    async confirmNav() {
      try {
        await ActivityAjax.postNavUpdate(this.iconTableData);

        this.$message.success("保存成功");
        await this.getActivityNavList();
      } catch (error) {
        this.$message.error(error);
      }
    },
    // 分享设置- 保存
    confirmShare(formName) {
      this.$refs[formName].validate(async valid => {
        if (valid) {
          try {
            await ActivityAjax.postActivityShare(this.shareForm);

            this.$message.success("保存成功");
            // await this.getActivityNavList();
          } catch (error) {
            this.$message.error(error);
          }
        } else {
          return false;
        }
      });
    }
  },
  created() {},
  mounted() {
    this.$nextTick(() => {
      this.getActivityNavList();
      this.getActivityShareInfo();
      this.getOptimizeData();
      // 初始化 上传
      this.upload = new VzanUpload({
        actId: 0,
        category: "common",
        extension: "jpg",
        fileType: "image"
      });
    });
  }
};
</script>

<style lang="scss">
.AdvancedSetup_wrapper {
  .icon_wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 6px;
    color: #409eff;
    border: 1px solid #d9ecff;
    background-color: #ecf5ff;
    background: #ebeef5;
    border-radius: 6px;
    cursor: pointer;
    img {
      width: 65px;
      height: 65px;
      border-radius: 6px;
    }
  }
  .form_tips {
    color: #888;
    font-size: 12px;
  }
  // 修改图标
  .icon_drawer {
    .el-drawer__body {
      padding: 0 20px;
      .icon_drawer_item {
        position: relative;
        width: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 6px;
        color: #409eff;
        border: 1px solid #d9ecff;
        background-color: #ecf5ff;
        background: #ebeef5;
        border-radius: 6px;
        cursor: pointer;
        img {
          width: 100px;
          height: 100px;
          border-radius: 6px;
          margin-bottom: 10px;
        }
        &.icon_drawer_item_chosen {
          border: 3px solid #2a75ed;
        }

        .vzanduihao {
          position: absolute;
          top: 10px;
          left: 10px;
          font-size: 20px;
          color: #2a75ed;
        }
      }
    }
  }
}
</style>
